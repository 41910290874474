
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    backbutton: {
      type: Boolean,
      default: true,
    },
    brandImgLogo: {
      type: String,
      required: true,
    },
    brandLogoClass: {
      type: String,
    },
    brandAltText: {
      type: String,
      required: true,
    },
    brandHeadline: {
      type: String,
      required: true,
    },
    brandGruendung: {
      type: String,
    },
    brandLongText: {
      type: String,
      required: true,
    },
    brandEmail: {
      type: String,
    },
    brandEmailText: {
      type: String,
    },
    brandWebsite: {
      type: String,
      required: true,
    },
    brandWebsiteText: {
      type: String,
      required: true,
    },
  },
});
// https://vuejs.org/v2/guide/components-props.html
