<style lang="scss"></style>

<template>
  <div class="brands-box-outer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="img-box-logo">
            <a :href="brandWebsite" target="_blank"><img :src="brandImgLogo" :alt="brandAltText" class="img-fluid" :class="brandLogoClass"/></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-10">
          <div class="txt-inner">
            <h1>{{ brandHeadline }}</h1>
            <div class="brandLongText" v-html="brandLongText"></div>
            <div class="gruendung" v-if="brandGruendung">
              {{ $t('General.gruendung') }}<span>{{ brandGruendung }}</span>
            </div>
          </div>
          <div class="txt-bottom">
            <div class="row">
              <div class="col-md-12 align-self-center">
                <a :href="brandWebsite" target="_blank"
                  ><span>{{ brandWebsiteText }}</span></a
                >
              </div>
              <!--<div class="col-md-6 align-self-center text-lg-right">
                <a :href="brandEmail"><span>{{ brandEmailText }}</span></a>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="row margin-t-m" v-if="backbutton">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'marketplaces-ueber-uns' }"
              ><span class="txt">{{ $t('General.back-to-marketplaces-overview') }}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    backbutton: {
      type: Boolean,
      default: true,
    },
    brandImgLogo: {
      type: String,
      required: true,
    },
    brandLogoClass: {
      type: String,
    },
    brandAltText: {
      type: String,
      required: true,
    },
    brandHeadline: {
      type: String,
      required: true,
    },
    brandGruendung: {
      type: String,
    },
    brandLongText: {
      type: String,
      required: true,
    },
    brandEmail: {
      type: String,
    },
    brandEmailText: {
      type: String,
    },
    brandWebsite: {
      type: String,
      required: true,
    },
    brandWebsiteText: {
      type: String,
      required: true,
    },
  },
});
// https://vuejs.org/v2/guide/components-props.html
</script>
